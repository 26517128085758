import { ref } from 'vue';
import addressValidationService from '@/services/address-validation.service';

export function useGoogleMapsAddressValidation({ form, model }: {form: any, model: any}) {
    const addressValidationRequestId = ref<string>(null);

    async function validateAddress() {
        const validationRequest = {
            previousResponseId: addressValidationRequestId.value,
            address: {
                city: form.value.city,
                company: '',
                countryCode: form.value.countryCode,
                district: '',
                email: '',
                name: '',
                phoneNo: '',
                postalCode: form.value.postalCode,
                regionCode: form.value.regionCode,
                street: form.value.address,
                subUnit: form.value.subUnit
            }
        };

        const response = await addressValidationService.validateAddress(validationRequest, 'Pages.Basket.InvalidAddressHeader', 'Pages.Basket.InvalidAddressText');
        addressValidationRequestId.value = response;
    }

    return {
        validateAddress
    };
}
