import { Ref, onMounted, ref, watch } from 'vue';
import CheckoutService from '@/services/checkout.service';
import { StorageService } from '@/core';
import { useAddressAutoComplete } from '@/services/address-auto-complete.service';
import { injectParentValidator } from '@/core/app.service';
import { useTranslations } from '@/core/translate.mixin';

interface IInvoiceAddress {
    city: string,
    extendedFields: { stateCode: string }
}

interface IYubinBangoRefs {
    region: Ref;
    locality: Ref;
}

export function useCustomerDetailsJapan<TInvoiceAddress extends IInvoiceAddress>(props: any, emit: any, refs: IYubinBangoRefs) {
    const selectedCustomerCountry = ref<InvoiceAddressCountryViewObject>({} as InvoiceAddressCountryViewObject);
    const selectedCustomerState = ref<StateViewObject>({} as StateViewObject);
    const form = ref<TInvoiceAddress>(props.value) as Ref<TInvoiceAddress>;

    // Inject validator
    const $validator = injectParentValidator();

    watch(() => props.value, onCustomerDetailsChange, { deep: true });

    function onCustomerDetailsChange() {
        form.value = props.value;
        emit('input', form.value);
        setSelectedCountryAndState();
    }

    const { setUpHiddenInputChange } = useAddressAutoComplete();

    const { t } = useTranslations();
    const labelNamespace = 'Pages.Checkout.Customer';
    const generalMaxLength = 35;
    const phoneNumberMaxLength = 16;
    const postalCodeLength = 7;

    onMounted(() => {
        const dictionary = {
            custom: {
                firstName: {
                    required: t(`${labelNamespace}.FirstNameRequiredErrorMessage`),
                    max: t(`${labelNamespace}.FirstNameMaxLengthErrorMessage`).replace('{0}', generalMaxLength.toString())
                },
                lastName: {
                    required: t(`${labelNamespace}.LastNameRequiredErrorMessage`),
                    max: t(`${labelNamespace}.LastNameMaxLengthErrorMessage`).replace('{0}', generalMaxLength.toString())
                },
                postalcode: {
                    required: t(`${labelNamespace}.PostalCodeRequiredErrorMessage`),
                    length: t(`${labelNamespace}.PostalCodeLengthErrorMessage`).replace('{0}', postalCodeLength.toString())
                },
                state: {
                    required: t(`${labelNamespace}.StateRequiredErrorMessage`)
                },
                city: {
                    required: t(`${labelNamespace}.CityRequiredErrorMessage`),
                    max: t(`${labelNamespace}.CityMaxLengthErrorMessage`).replace('{0}', generalMaxLength.toString())
                },
                address: {
                    max: t(`${labelNamespace}.AddressMaxLengthErrorMessage`).replace('{0}', generalMaxLength.toString())
                },
                phone: {
                    required: t(`${labelNamespace}.PhoneNumberRequiredErrorMessage`),
                    regex: t(`${labelNamespace}.PhoneNumberInvalidFormatErrorMessage`),
                    max: t(`${labelNamespace}.PhoneNumberMaxLengthErrorMessage`).replace('{0}', phoneNumberMaxLength.toString())
                },
                email: {
                    required: t(`${labelNamespace}.EmailAddressRequiredErrorMessage`),
                    email: t(`${labelNamespace}.EmailAddressInvalidFormatErrorMessage`)
                }
            }
        };
        $validator.localize('en', dictionary);

        setSelectedCountryAndState();
        if (selectedCustomerCountry.value !== undefined) {
            const customer = Object.assign(form.value, { countryCode: selectedCustomerCountry.value.id });
            emit('input', customer);
        }
        setUpHiddenInputChange(refs.region.value, onRegionChange);
        setUpHiddenInputChange(refs.locality.value, onLocalityChange);
    });

    function setSelectedCountryAndState() {
        selectedCustomerCountry.value = props.model.invoiceAddressCountries[0];
        selectedCustomerState.value = props.model.countryStates.find(x => x.country === props.model.invoiceAddressCountries[0].id).states.find(x => x.code === form.value.extendedFields.stateCode);
    }

    watch(() => selectedCustomerCountry.value, onSelectedCustomerCountryChange);

    function onSelectedCustomerCountryChange() {
        emit('changeCustomerCountry', selectedCustomerCountry.value);
    }

    function onCustomerStateChanged(state: StateViewObject) {
        const customer = Object.assign(form.value, { extendedFields: Object.assign(form.value.extendedFields, { stateCode: state.code }) });
        emit('input', customer);
        setCustomerInLocalStorage(CheckoutService.customerLocalStorageKey, customer);
    }

    function setCustomerInLocalStorage(key, value) {
        StorageService.setItemAs(key, value);
    }

    function blur() {
        setCustomerInLocalStorage(CheckoutService.customerLocalStorageKey, form.value);
    }

    function onRegionChange() {
        const prefecture = props.model.countryStates.find(x => x.country === props.model.invoiceAddressCountries[0].id).states.find(x => x.displayName === refs.region.value.value);
        if (prefecture) {
            selectedCustomerState.value = prefecture;
            setTimeout(() => $validator.validate('state'), 100);
            onCustomerStateChanged(selectedCustomerState.value);
        }
    }

    function onLocalityChange() {
        if (refs.locality.value.value) {
            form.value.city = refs.locality.value.value;
            setTimeout(() => $validator.validate('city'), 100);
        }
    }

    return {
        selectedCustomerCountry,
        selectedCustomerState,
        form,
        onCustomerStateChanged,
        blur,
        generalMaxLength,
        postalCodeLength,
        phoneNumberMaxLength
    };
}
