import { computed, ref, watch } from 'vue';
import { StorageService } from '@/core';
import { store } from '@/store';
import CheckoutService from '@/services/checkout.service';
import { useGoogleMapsAddressValidation } from './google-maps-address-validation.composable';

interface IShippingAddress {
    address: string,
    postalCode: string,
    countryCode: string
}

export function useDeliveryMethods<TShippingAddress extends IShippingAddress>(props, emit) {
    function fetchPickupShop(request: FindGlsShopWebRequest) : Promise<any> {
        return store.dispatch('checkoutb2c/fetchPickupShop', request);
    }

    const isLoadingPickupShops = computed<boolean>(() => store.getters['checkoutb2c/isLoadingPickupShops']);
    const pickupShops = computed<any[]>(() => store.getters['checkoutb2c/pickupShops']);

    const selectedShippingCountry = ref<AddressCountryViewObject>({} as AddressCountryViewObject);
    const selectedDeliveryMethod = ref<string>('');
    const selectedPickupShopId = ref<string>('');
    const selectedPickupShop = ref<any>({});
    const showPickupShopOtherAddressSearch = ref<boolean>(false);

    const glsAddress = ref<string>('');
    const glsPostalCode = ref<string>('');
    const selectedShippingState = ref<StateViewObject>({} as StateViewObject);

    const alternativeDelivery = ref<boolean>(false);

    const showAlternativePickupShopsErrorMessage = ref<boolean>(false);

    const form = ref<TShippingAddress>(props.value);
    const { validateAddress } = useGoogleMapsAddressValidation({ form, model: props.model });

    const isInvoiceCountryPrimaryCountry = ref<boolean>(props.isInvoiceCountryPrimaryCountry);

    function onDeliveryInfoChange(value: TShippingAddress) {
        emit('input', value);
        form.value = value;
        setSelectedCountryAndState();

        if (props.model.isUsMarket) {
            validateAddress();
        } else {
            loadPickupShops();
        }
    }

    function onMandatoryAlternativeDeliveryChange(value: boolean) {
        if (value) {
            alternativeDelivery.value = true;
        }
    }

    function mount() {
        setSelectedCountryAndState();

        setAlternativeAddress();
        selectedPickupShopId.value = props.model.pickupShop;
        isInvoiceCountryPrimaryCountry.value = props.isInvoiceCountryPrimaryCountry;

        setSelectedDeliveryMethod();
    }

    function setSelectedDeliveryMethod() {
        const mustHaveAlternativDelivery = !props.isInvoiceCountryPrimaryCountry;
        const selectedDeliveryMethodObject = props.model.deliveryMethods.find(d => d.id === selectedDeliveryMethod.value);
        selectedDeliveryMethod.value = props.model.deliveryMethod;

        if (mustHaveAlternativDelivery) {
            selectedDeliveryMethod.value = props.model.deliveryMethods.find(dm => dm.allowAlternateDeliveryAddress).id;
        } else if (selectedDeliveryMethodObject && selectedDeliveryMethodObject.loadGlsPickupShops) {
            loadPickupShops();
        } else if (selectedDeliveryMethodObject === undefined || !selectedDeliveryMethodObject.loadGlsPickupShops) {
            selectedDeliveryMethod.value = props.model.deliveryMethods[0].id;
        }
    }

    function setSelectedCountryAndState() {
        const defaultCountry = props.model.shippingAddressCountries[0];
        if (props.model.shippingAddressCountries.length === 1) {
            selectedShippingCountry.value = defaultCountry;
        } else {
            selectedShippingCountry.value = props.model.shippingAddressCountries.find(c => c.countryCode === props.value.countryCode) || defaultCountry;
        }
        if (props.value.extendedFields) {
            selectedShippingState.value = props.model.countryStates.find(x => x.country === defaultCountry.countryCode).states.find(c => c.code === props.value.extendedFields.stateCode);
        }
    }

    function setAlternativeAddress() {
        const alternative: boolean = StorageService.getItemAs(CheckoutService.alternativeDeliveryAddressKey);
        if (alternative !== null) {
            alternativeDelivery.value = !!alternative;
            return;
        }
        alternativeDelivery.value = props.model.useAlternativeDeliveryAddress;
    }

    function requestAlternativePickupShops(glsAddress, glsPostalCode) {
        showAlternativePickupShopsErrorMessage.value = false;

        if (!(glsAddress && glsAddress.trim()) || !(glsPostalCode && glsPostalCode.trim())) {
            showAlternativePickupShopsErrorMessage.value = true;
            return;
        }

        const request: FindGlsShopWebRequest = createGlsFindShopRequest(glsAddress, glsPostalCode, props.customer.countryCode);
        fetchPickupShop(request).then(() => {
            if (pickupShops.value.length > 0) {
                selectedPickupShop.value = pickupShops.value[0];
                selectedPickupShopId.value = pickupShops.value[0].id;
            }
        });
    }

    function createGlsFindShopRequest(address: string, postalCode: string, invoiceMarket: string): FindGlsShopWebRequest {
        return { address, postalCode, maxShops: 10, invoiceMarket };
    }

    function loadPickupShops(newInitialPickupShop?: any) {
        if (isInvoiceCountryPrimaryCountry.value && props.customer.address && props.customer.postalCode) {
            fetchPickupShop(createGlsFindShopRequest(props.customer.address, props.customer.postalCode, props.customer.countryCode)).then(() => {
                selectedPickupShop.value = getInitialPickupShop(newInitialPickupShop);
                if (!selectedPickupShop.value) {
                    console.warn('No initial pickup shop found');
                } else {
                    selectedPickupShopId.value = selectedPickupShop.value.id;
                }
            });
        }
    }

    function getInitialPickupShop(newInitialPickupShop?: any) {
        if (selectedPickupShopId.value && !newInitialPickupShop) {
            return pickupShops.value.find(s => s.id === selectedPickupShopId.value);
        } else {
            return pickupShops.value[0];
        }
    }

    watch(() => alternativeDelivery.value, setAlternativeAddressInLocalStorage);
    function setAlternativeAddressInLocalStorage() {
        emit('setAlternativeDelivery', alternativeDelivery.value);
        // We only want to use the deliveryInfo.countryCode if alternativeDelivery is true
        if (alternativeDelivery.value && selectedShippingCountry.value) {
            emit('input', Object.assign(props.value, { countryCode: selectedShippingCountry.value.countryCode }));
        }

        StorageService.setItem(CheckoutService.alternativeDeliveryAddressKey, alternativeDelivery.value);
        emit('updateTaxTotal');
    }

    watch(() => selectedPickupShopId.value, setSelectedPickupShop);
    function setSelectedPickupShop() {
        emit('setSelectedPickupShop', selectedPickupShop.value, selectedPickupShopId.value);
    }

    function updateShippingCountry(country: AddressCountryViewObject) {
        selectedShippingCountry.value = country;
        emit('updateShippingCountry', country);
    }

    watch(() => selectedDeliveryMethod.value, onDeliveryMethodChange);
    function onDeliveryMethodChange(deliveryMethodId: string) {
        const deliveryMethod = props.model.deliveryMethods.find(d => d.id === deliveryMethodId);
        if (deliveryMethod && deliveryMethod.loadGlsPickupShops) {
            loadPickupShops();
        } else {
            selectedPickupShopId.value = null;
        }
        emit('setDeliveryMethod', deliveryMethodId);
    }

    function onPickupShopSelect(shop: any) {
        selectedPickupShopId.value = shop.id;
    }

    const isAddressFulfilled = computed<boolean>(() => {
        return alternativeDelivery.value ? isAlternativeDeliveryAddressFulfilled.value : isCustomerAddressFulfilled.value;
    });

    const isCustomerAddressFulfilled = computed<boolean>(() => {
        return !!props.customer.address && !!props.customer.postalCode;
    });

    const isAlternativeDeliveryAddressFulfilled = computed<boolean>(() => {
        return !!form.value.address && !!form.value.postalCode;
    });

    watch(() => props.value, onDeliveryInfoChange, { deep: true });
    watch(() => props.mandatoryAlternativeDelivery, onMandatoryAlternativeDeliveryChange);

    return {
        mount,
        onDeliveryMethodChange,
        requestAlternativePickupShops,
        setAlternativeAddressInLocalStorage,
        setSelectedPickupShop,
        updateShippingCountry,
        onPickupShopSelect,
        loadPickupShops,
        setSelectedDeliveryMethod,
        form,
        selectedDeliveryMethod,
        selectedPickupShop,
        alternativeDelivery,
        isLoadingPickupShops,
        pickupShops,
        glsAddress,
        glsPostalCode,
        showAlternativePickupShopsErrorMessage,
        showPickupShopOtherAddressSearch,
        isInvoiceCountryPrimaryCountry
    };
}
